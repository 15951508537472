<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/4 flex items-center">
        <icon
          :data="icons.entreprise"
          height="20"
          width="20"
          class="mr-2 cursor-pointer"
          original
        />

        <div>{{ item.fullName }}</div>
      </div>

      <div class="w-1/4 ml-4 flex items-center font-c7">
        <nameLabel
          :first="item.body.lastName.length > 0 ? item.body.lastName.substr(0,1) : item.body.firstName.substr(0,1)"
          :second="item.body.lastName.length > 0 ? item.body.lastName.substr(1,1) : item.body.firstName.substr(1,1)"
          height="35px"
          width="35px"
          size="15px"
        />
        <div class="ml-4">
          <div class="">
            {{ item.body.firstName }}  {{ item.body.lastName !== item.body.firstName ? item.body.lastName : '' }}
          </div>
        </div>
      </div>

      <div class="w-1/5 ml-4">
        {{ item.categorie }}
      </div>

      <div class="w-1/5 ml-4 flex items-center">
        {{ item.taille }}
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        Client SDF
      </div>
    </div>
  </div>
</template>

<script>
import email from '../../../assets/icons/email.svg'
import entreprise from '../../../assets/icons/ic-entreprise.svg'
import nameLabel from '../../helper/add/nameLabel'

export default {
  name: "Index",
  components: {
   nameLabel
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        entreprise,
        email,
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
