<template>
  <div class="the_header">
    <div class="button">
      <export-excel
        class="btn btn-default"
        :data="json_data"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="Commerce.xls"
      >
        <bouton
          label="Exporter"
          border="1px solid #fff"
          background="#312879"
          color="#fff"
          size="14px"
          :icon="icons.esport"
        />
      </export-excel>
    </div>
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import esport from '../../../assets/icons/export.svg'

export default {
  name: 'Index',
  components: {
    bouton
  },

  props: {
    donne: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        esport
      },
      loaderColor: '#fff',
      charge: false,
      json_fields: {
        'No': 'i',
        'Entreprise': 'e',
        'Gérant': 'g',
        'Téléphone': 't',
        'Email': 'email',
        'Categorie': 'c',
        'Site': 's',
        'Taille': 'taille',
        'Autres informations': 'autre',
        "Date d'enregistrement": 'date'
      },

      json_data: [
      ],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
    }
  },

  mounted () {
    // console.log(this.donne)
    const data = this.donne
    for (let item in data) {
        const obj = {
          i: parseInt(item) + 1,
          e: data[item].fullName,
          g: data[item].body.lastName,
          t: data[item].body.phone,
          c: data[item].categorie,
          s: data[item].webSite,
          email: data[item].body.email,
          taille: data[item].taille,
          autre: '-',
          date: new Date(data[item].createdAt).toLocaleDateString()
        }

        if (data[item].body.body) {
          if (data[item].body.body.toString() !== '[object Object]') {
            // console.log(data[item].body.body.toString())
            obj.autre = data[item].body.body.toString()
          }
        }
        if (data[item].body.firstName){
          obj.g = obj.g + ' ' + data[item].body.firstName
        }

        this.json_data.push(obj)
    }
  },

  methods: {
    conversion (index) {
      return new Date(index).toLocaleDateString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

.the_header{
  font-family: 'Roboto', sans-serif;
  color: #000000;
}
.buttons{
  height: 2rem;
}
</style>
